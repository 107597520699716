@import "../../variables";

section#final {
  border-bottom: none;

  h3, h6 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .logo {
    position: relative;

    img {
      position: absolute;
      width: 50%;
      bottom: 3rem;
      right: 3rem;
    }
  }

  .rru-row {
    min-width: 100vw;
    margin-left: -50px; // Offset the container and main padding.
    margin-bottom: 0;
    padding-bottom: 0;
    background-color: $rru;

    .rru {
      width: 50%;
      align-self: center;
      justify-self: center;
    }
  }
}