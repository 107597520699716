// Colours
$white: #FFF;
$black: #000;
$grey: #b0bec5;
$dark-grey: rgb(104, 106, 110);
$orange: rgb(255, 85, 22);
$cyan: rgb(108, 203, 193);
$egg-shell: rgb(246, 245, 240);

$brand-primary: #242F60;
$rru: #002232;