@import "variables";

section {
  border-bottom: 1px solid $grey;
}

a {
  color: $orange;
}

footer {
  margin-top: 0 !important;
}