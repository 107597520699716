section#cover {
  text-align: center;

  h1, h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .law-tech-uk-logo {
    margin-top: 1rem;
    width: 250px;
  }
}